import React from "react";
import styled from "styled-components";

import logoImage from "../img/symbol-brand.png";

const Header = styled.header`
  background-color: #2d66af;
  max-height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 10px 0px 10px 20px;
`;

const Img = styled.img`
  height: 2em;
  padding: 0;
  margin: 0;
  padding-right: 0.5em;
`;

const H1 = styled.h1`
  padding: 0;
  margin: 0;
  color: #fff;
  font-size: 1.8em;
  font-weight: bold;
  text-align: center;
`;

const HeaderIitleComponent = () => {
  return (
    <Header>
      <Img src={logoImage} alt="로고" />
      <H1>조사료 토양수분 모니터링 시스템</H1>
    </Header>
  );
};

export default HeaderIitleComponent;
