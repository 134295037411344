import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";

import SoilMoistureChart from "./SoilMoistureChart";
import CsvDownloadComponent from "./CsvDownloadComponent";
import { getStationSoilReport } from "../context/api";

const SoilDataComponent = (props) => {
  const { setLoading, setError, spotInfo, targetYear, targetSoilTexture } =
    props;
  const [reportData, setReportData] = useState();

  const generateFileName = () => {
    return `${spotInfo.stationName}(${spotInfo.stationCode})-${targetSoilTexture.labelKo}(${targetSoilTexture.value})-${targetYear}년`;
  };

  useEffect(() => {
    if (
      spotInfo &&
      targetYear &&
      targetSoilTexture &&
      targetSoilTexture.value
    ) {
      getStationSoilReport(
        (data) => {
          setReportData(data);
        },
        (data) => {
          setLoading(data);
        },
        (data) => {
          setError(data);
        },
        targetYear,
        targetSoilTexture.value,
        spotInfo
      );
    }
  }, [spotInfo, targetYear, targetSoilTexture]);

  if (
    targetYear &&
    targetSoilTexture &&
    targetSoilTexture.value &&
    spotInfo &&
    reportData
  ) {
    return (
      <div>
        {reportData &&
        reportData.observedSoilMoistures &&
        reportData.observedSoilMoistures.length > 0 ? (
          <>
            <SoilMoistureChart data={reportData} />
          </>
        ) : (
          <p>해당 데이터가 없습니다.</p>
        )}
        <hr />
        <CsvDownloadComponent
          filename={generateFileName()}
          headers={reportData.csvHeaders}
          data={reportData.csvData}
        />
      </div>
    );
  }

  return (
    <div>
      {reportData &&
      reportData.observedSoilMoistures &&
      reportData.observedSoilMoistures.length > 0 ? (
        <>
          <SoilMoistureChart data={reportData} />
        </>
      ) : (
        <p>해당 데이터가 없습니다.</p>
      )}
      <hr />
      <CsvDownloadComponent />
    </div>
  );
};

export default SoilDataComponent;
