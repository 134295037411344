import React, { useEffect } from "react";
import { CSVLink } from "react-csv";

const CsvDownloadComponent = (props) => {
  const { filename, headers, data } = props;

  useEffect(() => {}, [headers, data]);

  if (headers && data && data.length > 1) {
    return (
      <CSVLink
        type="button"
        className="btn active"
        filename={filename ? `${filename}.csv` : "file.csv"}
        headers={headers}
        data={data}
        target="_blank"
      >
        CSV 다운로드
      </CSVLink>
    );
  }

  return (
    <button
      type="button"
      className="btn inactive"
      onClick={() => {
        alert("다운받을 수 있는 데이터가 없습니다.");
      }}
    >
      CSV 다운로드
    </button>
  );
};

export default CsvDownloadComponent;
