import { PepperLateBlight } from "../PBCastModel/PepperLateBlight";
import { getDailyMoistureEstimationValues } from "../SimpleMositureEstimationModel/Estimation";

export const getRefinedRDAStations = (data) => {
  const newData =
    data.features && data.features.length > 0 ? data.features : [];
  return newData.map((item) => {
    return {
      id: item.id,
      stationCode: item.id,
      stationName:
        item.properties && item.properties.name ? item.properties.name : null,
      stationType:
        item.properties && item.properties.type ? item.properties.type : null,
      lon:
        item.geometry && item.geometry.coordinates
          ? parseFloat(item.geometry.coordinates[0])
          : 127.7669,
      lat:
        item.geometry && item.geometry.coordinates
          ? parseFloat(item.geometry.coordinates[1])
          : 35.9078,
      elv: item.properties && item.properties.elev ? item.properties.elev : 1,
    };
  });
};

export const getRefinedSoilData = (data, spotInfo, targetSoilValue) => {
  const DEFAULT_SOIL_VALUE = 5;
  const csvHeaders = [
    { label: "날짜", key: "tm" },
    { label: "관측지점id", key: "stationId" },
    { label: "고도", key: "elev" },
    { label: "위도", key: "lat" },
    { label: "경도", key: "lon" },
    { label: "관측 평균기온", key: "ta" },
    { label: "관측 습도", key: "hm" },
    { label: "관측 강수량", key: "rn" },
    // { label: "관측", key: "tn" },
    // { label: "관측", key: "tx" },
    { label: "관측 토양수분", key: "sw" },
    { label: "설정된 토성값", key: "soilIndex" },
    { label: "PBCast 모델 추정값", key: "PLB_WC" },
    { label: "Simple 모델 추정값", key: "simpleMoistureEstimationValue" },
  ];

  const getDailyWeathers = (data) => {
    return data.map((item) => {
      return {
        // ...item,
        tm: item.tm, // 날짜
        stationId: spotInfo.stationCode,
        elev: spotInfo.elv, // 고도정보 없음
        lat: spotInfo.lat, // 위도 정보 없음
        lon: spotInfo.lon, // 고도 정보 없음
        ta: item.ta, // 관측 평균기온
        hm: item.rh, // 관측 습도
        rn: item.rn, // 관측 강수량
        tn: item.tn,
        tx: item.tx,
        soilIndex: targetSoilValue ? targetSoilValue : DEFAULT_SOIL_VALUE,
        sw: item.sw ? item.sw : 0, // 측정 토양수분
      };
    });
  };

  const getDailyMoistureEstimationsWithDailyWeathers = (
    dailyWeathers,
    targetSoilIndex
  ) => {
    // 고추역병 모델 계산
    const model = new PepperLateBlight();
    model.setSoil(targetSoilIndex ? targetSoilIndex : DEFAULT_SOIL_VALUE);
    return model.run(dailyWeathers).map((item) => {
      return {
        ...item,
        PLB_WC: item.PLB_WC ? item.PLB_WC * 100 : 0, // PLB_WC * 100을 해서 퍼센트값을 얻음
      };
    });
  };

  const getCsvData = (
    csvHeaders,
    dailyWeathers,
    dailyFBCastMoistures,
    dailySimpleMoistures
  ) => {
    return dailyWeathers.map((d, index) => {
      const row = {};
      for (let h of csvHeaders) {
        row[h.key] = d[h.key];
      }
      row["PLB_WC"] = dailyFBCastMoistures[index]
        ? dailyFBCastMoistures[index]["PLB_WC"]
        : null;
      row["simpleMoistureEstimationValue"] = dailySimpleMoistures[index];
      return row;
    });
  };

  const dailyWeathers = getDailyWeathers(data);
  const dailyFBCastMoistures = getDailyMoistureEstimationsWithDailyWeathers(
    getDailyWeathers(data),
    targetSoilValue
  );
  const dailySimpleMoistures = getDailyMoistureEstimationValues(dailyWeathers);

  return {
    dates: data ? dailyWeathers.map((item) => item.tm) : [],
    observedSoilMoistures: data ? dailyWeathers.map((item) => item.sw) : [],
    fBCastMoistures: data
      ? dailyFBCastMoistures.map((item) => item.PLB_WC)
      : [],
    simpleMoistures: data ? dailySimpleMoistures : [],
    csvHeaders: csvHeaders,
    csvData: data
      ? getCsvData(
          csvHeaders,
          dailyWeathers,
          dailyFBCastMoistures,
          dailySimpleMoistures
        )
      : [],
  };
};
