import React from "react";
import * as L from "leaflet";
import {
  Map,
  TileLayer,
  WMSTileLayer,
  Marker,
  Tooltip,
  // Popup,
  ScaleControl,
  ZoomControl,
  GeoJSON,
} from "react-leaflet";
import styled from "styled-components";

import "./MapContainer.css";
import markerImg from "../img/marker.png";
import markerActiveImg from "../img/marker-active.png";
import KOREA_MAP from "../data/map/KOREA-MAP-V3.json";

const markerIcon = new L.icon({
  iconUrl: markerImg,
  iconRetinaUrl: markerImg,
  iconSize: [22],
  iconAnchor: [11, 22],
});

const markerActiveIcon = new L.icon({
  iconUrl: markerActiveImg,
  iconRetinaUrl: markerActiveImg,
  iconSize: [30],
  iconAnchor: [15, 30],
});

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const MapContainer = (props) => {
  const { targetProvince, totalSpots, selectSpot } = props;

  const mapUrls = {
    base: "https://xdworld.vworld.kr/2d/Base/201612/{z}/{x}/{y}.png",
    hybrid: "https://xdworld.vworld.kr/2d/Hybrid/201612/{z}/{x}/{y}.png",
    satellite: "https://xdworld.vworld.kr/2d/Satellite/201612/{z}/{x}/{y}.jpeg",
  };

  // const onClickMap = (e) => {
  //   setMarker(e.latlng);
  // };

  const onEachFeature = (feature, layer) => {
    layer.on({
      mouseover: () => {
        console.log("mouseover");
      },
      mouseout: () => {
        console.log("mouseout");
      },
      click: () => {
        console.log("click");
      },
    });
  };
  return (
    <Wrapper>
      <Map
        center={
          targetProvince && targetProvince.center
            ? [targetProvince.center.lat, targetProvince.center.lon]
            : [35.9078, 127.9]
        }
        zoom={targetProvince && targetProvince.zoom ? targetProvince.zoom : 11}
        minZoom={7}
        style={{ width: "100%" }}
        // onClick={onClickMap}
        zoomControl={false}
      >
        <ZoomControl position="bottomleft" />
        <ScaleControl
          metric={true}
          imperial={false}
          ScaleControl={true}
        ></ScaleControl>
        <TileLayer url={mapUrls.satellite} attribution="VWORLD" />
        <WMSTileLayer url={mapUrls.hybrid} />
        {totalSpots
          ? totalSpots.map((spot) => (
              <Marker
                onclick={() => {
                  selectSpot(spot);
                }}
                key={spot.id}
                position={[spot["lat"], spot["lon"]]}
                icon={spot.isSelected ? markerActiveIcon : markerIcon}
                zIndexOffset={spot.isSelected ? 99 : 1}
              >
                <Tooltip>{`${spot.stationName ? spot.stationName : "-"}(${
                  spot.stationCode ? spot.stationCode : "-"
                }: ${spot.stationType ? spot.stationType : "-"})`}</Tooltip>
              </Marker>
            ))
          : null}
        <GeoJSON
          // onclick={() => {
          //   console.log("geojsonclick!!");
          // }}
          color={"black"}
          weight={2}
          opacity={0.5}
          fillColor={"white"}
          // fillOpacity={0.01}
          data={KOREA_MAP}
          // onEachFeature={onEachFeature}
          // data={JEK_MAP}
        />
      </Map>
    </Wrapper>
  );
};

export default MapContainer;
