import axios from "axios";

import { getRefinedRDAStations, getRefinedSoilData } from "./refineAPIResult";

export const getRdaStations = async (
  setDataCallBack,
  setLoadingCallBack,
  setErrorCallBack,
  targetYear
) => {
  const api = `https://aggregate.epinet.kr/station/rda/`;

  try {
    setLoadingCallBack(true);
    setErrorCallBack(false);
    await axios.get(api).then((response) => {
      const data = response.data;
      setDataCallBack(getRefinedRDAStations(data));
    });
  } catch (e) {
    if (e.response && e.response.status === 404) {
      setErrorCallBack("NOT FOUND");
    } else {
      setErrorCallBack(e);
    }
  }
  setLoadingCallBack(false);
};

export const getStationSoilReport = async (
  setDataCallBack,
  setLoadingCallBack,
  setErrorCallBack,
  targetYear,
  targetSoilIndex,
  spotInfo
) => {
  const api = `https://aggregate.epinet.kr/station/rda/${spotInfo.id}/daily/${targetYear}0101/${targetYear}1231/json/`;

  try {
    setLoadingCallBack(true);
    setErrorCallBack(false);
    await axios.get(api).then((response) => {
      const data = response.data;
      setDataCallBack(getRefinedSoilData(data, spotInfo, targetSoilIndex));
    });
  } catch (e) {
    if (e.response && e.response.status === 404) {
      setErrorCallBack("NOT FOUND");
    } else {
      setErrorCallBack(e);
    }
  }
  setLoadingCallBack(false);
};
