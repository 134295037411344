export class Model {
  constructor(cls, data) {
    this.cls = cls;
    this.data = data;
  }

  repr() {
    return "Model Class";
  }

  modelKo(modelType) {
    return {
      anthrax: "탄저병",
      lateBlight: "역병",
      pbCast: "pbCast",
    }[modelType];
  }

  run() {
    if (!this.cls) {
      alert("모델이 아직 구현되지 않았습니다.");
      throw new Error("run() should Implement");
    }
    console.log(`${this.cls.repr()} Method 'run()'`);
    return this.cls.run(this.data);
  }
}
