import moment from "moment";

import { Model } from "./Model";

export class PepperLateBlight extends Model {
  // 토성(1 ~ 13)에 따른 토양 특성값 배열
  // 포장 용수량(Theta_33)
  THETA_33 = Object.freeze([
    0, 0.025, 0.156, 0.156, 0.254, 0.271, 0.089, 0.253, 0.341, 0.33, 0.38,
    0.402, 0.324, 0.324,
  ]);

  // 영구 위조점(Theta_1500)
  THETA_1500 = Object.freeze([
    0, 0.002, 0.056, 0.056, 0.127, 0.127, 0.022, 0.154, 0.208, 0.209, 0.256,
    0.297, 0.229, 0.229,
  ]);

  // 최대 유효수분함유량(Theta_av)
  THETA_AV = Object.freeze([
    0, 0.023, 0.1, 0.1, 0.127, 0.145, 0.067, 0.099, 0.133, 0.12, 0.124, 0.105,
    0.095, 0.095,
  ]);

  // 공극률(Porosity)
  POROSITY = Object.freeze([
    0, 0.437, 0.453, 0.453, 0.463, 0.501, 0.437, 0.398, 0.471, 0.464, 0.479,
    0.475, 0.43, 0.43,
  ]);

  // 유주자 발아 불가능 점(Theta_zp)
  THETA_ZP = Object.freeze([
    0, 0.005, 0.075, 0.075, 0.156, 0.158, 0.033, 0.178, 0.24, 0.239, 0.288,
    0.325, 0.254, 0.254,
  ]);

  soil = -1;

  constructor() {
    super(null, null);
  }

  repr() {
    return "PepperLateBlight Class";
  }

  setSoil(value) {
    this.soil = value;
  }

  run(inputData) {
    // const data = inputData.getDailyCsv();
    const result = this.calcModule(inputData);
    // console.log("soil", this.soil);
    // console.log("result", result);
    // return convertToCSV(JSON.stringify(result));
    return result;
  }

  calcModule(weatherInfo) {
    const weatherInfo_ = weatherInfo.map((x) => Object.assign({}, x));

    const soil = this.soil;
    const theta33 = this.THETA_33;
    const theta1500 = this.THETA_1500;
    const thetaAv = this.THETA_AV;
    const porosity = this.POROSITY;
    const thetaZp = this.THETA_ZP;
    let CN = 0;
    let dap = 0; // 강우 후 일수
    let sumP5ds = []; // 최근 5일 강수량 합
    let fnKcb = this.getKcb;
    return weatherInfo_
      .map(function (obj, idx) {
        let reObj = obj;
        let ta = obj["ta"]; // 평균기온
        let hm = obj["hm"]; // 상대습도
        let rn = obj["rn"]; // 강우량
        let sumP5d = 0; // 최근 5일 강수량 합

        reObj["PLB_WC"] = null; // 토양수분함량
        reObj["PLB_ID"] = null; // 오늘의 전염원 농도
        reObj["PLB_ZSPG"] = null; // 발아하지 않은 유주자낭 개수
        reObj["PLB_IR"] = null; // 감염위험도
        let tm = moment(obj["tm"]);
        if (sumP5ds.length > 5) {
          sumP5ds.shift();
        }

        if (!(obj["rn"] > 0)) {
          dap += 1;
        } else {
          dap = 0;
        }

        // 01-01부터 12-31까지의 토양수분을 계산하기 위해 아래의 로직을 주석처리함
        // if (!("05-01" <= tm.format("MM-DD") && tm.format("MM-DD") <= "10-31")) {
        //   sumP5ds.push(rn);
        //   return reObj;
        // }

        let oldObj, oldPlbWc, oldPlbId, oldPlbId1, oldPlbZspg;
        oldObj = weatherInfo_[idx - 1];
        oldPlbWc = oldObj?.PLB_WC || theta33[soil];
        oldPlbId = oldObj?.PLB_ID || 0;
        oldPlbZspg = oldObj?.PLB_ZSPG || 0;

        try {
          oldPlbId1 = weatherInfo_[idx - 2]["PLB_ID"] || 0;
        } catch (e) {
          oldPlbId1 = 0;
        }

        if (idx >= 4) {
          sumP5d = sumP5ds.reduce((acc, cur) => acc + cur) / 5;
        }

        if (sumP5d < 35.56) {
          CN = 44;
        } else if (sumP5d <= 53.34) {
          CN = 65;
        } else {
          CN = 81;
        }

        // S = 23400 / CN - 254
        // 최대잠재보유수량(mm)
        let S = 23400 / CN - 254;

        // R = (P - 0.2 * S)^2 / (P + 0.8 * S)
        // 일적산 유거량(mm)
        // (P: daily rainfall)
        let R = rn - 0.2 * S;
        R = (R * R) / (rn + 0.8 * S);

        // E0TM = 6.11 * E ^ (17.4 * AirTemp / (AirTemp + 239))
        // 일평균기온에서의 포화수증기압(mb)
        let E0TM = 6.11 * Math.pow(Math.E, (17.4 * ta) / (ta + 239));

        // ETM = RH * E0TM / 100
        // 일평균기온에서의 평균수증기압(mb)
        let ETM = (hm * E0TM) / 100;

        // PET = (E0TM - ETM) / 2
        // 스트레스를 받지 않은 상태에서의 기준작물(잔디 또는 알팔파)의 증발산량(mm/day)
        let PET = (E0TM - ETM) / 2;

        // Kcb: 기준작물계수
        let Kcb;
        try {
          Kcb = fnKcb(Number(tm.format("MM")), Number(tm.format("DD")));
        } catch (e) {
          // console.log(e);
          Kcb = 0; // 01-01부터 12-31까지의 토양수분 계산을 위해서 5/1 이전 10/31이후는 Kcb값을 0으로 임의설정함
          // return reObj;
        }

        // console.log('kcb', Kcb);
        // Aw = (OLD_WC - Theta_1500) * 100 / Theta_av
        // 유효수분함수량(%)
        let Aw = ((oldPlbWc - theta1500[soil]) * 100) / thetaAv[soil];

        // Ka = ln(Aw + 1) / ln(101)
        // 유효수분에 따른 계수 (최대값 1)
        let Ka = Math.log(Aw + 1) / Math.log(101);

        // Ks = (2.0 - Kcb) * E ^ (-0.5 * DAP)
        // if (DAP < 1 || DAP > 4) Ks = 0;
        // 강우 후 표토 증발 상승에 의한 계수 (강우 후 4일까지 고려)
        let Ks = 0;
        if (1 <= dap && dap <= 4) {
          Ks = (2.0 - Kcb) * Math.pow(Math.E, -0.5 * dap);
        }

        // Kc = Kcb * Ka + Ks
        // 작물계수(crop coefficient)
        let Kc = Kcb * Ka + Ks;

        // ET = Kc * PET
        // 일적산 증발산량(mm)
        let ET = Kc * PET;

        // WC = OLD_WC + (D - R - ET) / 300
        // (D: daily rainfall)
        // 토양 수분 함량(%; volumetric water content)
        let WC = oldPlbWc + (rn - R - ET) / 300;

        // 비가 온 다음날(DAP = 1)일 경우
        if (dap === 1) {
          // WC는 포장용수량을 초과할 수 없음
          if (WC > theta33[soil]) {
            WC = theta33[soil];
          }
        } else {
          // WC는 공극률을 초과할 수 없음
          if (WC > porosity[soil]) {
            WC = porosity[soil];
          }
        }

        // WC는 영구 위조점 아래로 내려갈 수 없음
        if (WC < theta1500[soil]) {
          WC = theta1500[soil];
        }

        // **********************************
        // 감염 위험도 (infection risk) 추정 시작
        // **********************************

        // 일평균기온을 이용한 일평균 지온 추정
        // (Reg. model using SunMoon Univ Field Obs; R2 = 0.9064)
        let GndTemp = 0.9198 * ta + 3.192;

        // 지온을 이용한 이상적인 유주자낭 형성 개수 추정
        let log10_ZSPG =
          -0.0173 * GndTemp * GndTemp + 0.8674 * GndTemp - 9.0825;
        let ideal_ZSPG = Math.pow(10, log10_ZSPG) - 1;
        if (ideal_ZSPG < 0) {
          ideal_ZSPG = 0;
        }

        // 토양 수분 함량을 감안한 실제 유주자낭 개수
        let real_ZSPG = ideal_ZSPG * WC;

        // 전염원 양을 초기화함
        let ID;

        // 토양 수분 함량이 높으면 유주자낭 또는 유주자가 발아함
        if (WC >= thetaZp[soil]) {
          // 온도에 따른 전염원 양 계산
          ID = real_ZSPG + oldPlbZspg;

          // 온도가 낮을 경우 유주자낭에서 터져 나온 유주자가 발아하므로
          // 전염원의 양이 더 많아짐
          if (GndTemp < 25) {
            ID *= 48.5 - 1.9 * GndTemp;
          }

          // 이제 유주자낭 또는 유주자는 모두 발아하였음
          real_ZSPG = 0;
        } else {
          // 토양 수분 함량이 낮으면 유주자낭 또는 유주자가 발아하지 않음
          // 발아하지 않으므로 전염원의 양은 0이 됨
          ID = 0;

          // 어제의 발아하지 않은 유주자낭 또는 유주자를 누적함
          real_ZSPG += oldPlbZspg;
        }
        // 감염 위험도 계산
        let ir;
        if (ID * oldPlbId * oldPlbId1 !== 0) {
          ir = ID + oldPlbId + oldPlbId1;
        } else {
          ir = 0;
        }

        sumP5ds.push(rn);

        reObj["PLB_WC"] = WC; // 토양수분함량
        reObj["PLB_ID"] = ID; // 오늘의 전염원 농도
        reObj["PLB_ZSPG"] = real_ZSPG; // 발아하지 않은 유주자낭 개수
        reObj["PLB_IR"] = ir; // 감염위험도
        return reObj;
      })
      .filter((obj) => !!obj);
  }

  getKcb(month, day) {
    // console.log(month, day, Math.ceil(day / 5) - 1);
    try {
      return {
        5: [0.75, 0.83, 0.88, 0.9, 0.9, 0.8, 0.8], // 5월 <= 5일(0), <= 10일(1), <= 15일(2), <= 20일(3), <= 25일(4), <= 30,31일(5,6)
        6: [0.92, 0.84, 0.81, 1.0, 0.96, 0.97, 0.97],
        7: [1.1, 0.98, 1.28, 1.02, 1.09, 1.13, 1.13],
        8: [1.21, 1.39, 1.2, 1.42, 1.54, 1.49, 1.49],
        9: [1.21, 1.34, 1.21, 1.31, 1.16, 1.41, 1.41],
        10: [1.27, 1.32, 1.17, 1.03, 0.7, 0.8, 0.8],
      }[month][Math.ceil(day / 5) - 1];
    } catch (e) {
      throw new Error(`${month}월 ${day}일의 기준 작물계수값을 알 수 없음`);
    }
  }
}
