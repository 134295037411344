export const provinces = [
  {
    id: 1,
    value: 1,
    titleKo: "전체",
    center: { lat: 35.9078, lon: 128.7 },
    zoom: 7.3,
  },
  {
    id: 2,
    value: 2,
    titleKo: "서울특별시",
    center: { lat: 37.52, lon: 127.2 },
    zoom: 11,
  },
  {
    id: 3,
    value: 3,
    titleKo: "부산광역시",
    center: { lat: 35.1, lon: 129.3 },
    zoom: 11,
  },
  {
    id: 4,
    value: 4,
    titleKo: "대구광역시",
    center: { lat: 35.75, lon: 129.1 },
    zoom: 10,
  },
  {
    id: 5,
    value: 5,
    titleKo: "인천광역시",
    center: { lat: 37.4563, lon: 126.88 },
    zoom: 11,
  },
  {
    id: 6,
    value: 6,
    titleKo: "광주광역시",
    center: { lat: 35.13, lon: 126.999 },
    zoom: 11,
  },
  {
    id: 7,
    value: 7,
    titleKo: "대전광역시",
    center: { lat: 36.33, lon: 127.6 },
    zoom: 11,
  },
  {
    id: 8,
    value: 8,
    titleKo: "울산광역시",
    center: { lat: 35.5, lon: 129.49 },
    zoom: 11,
  },
  {
    id: 9,
    value: 9,
    titleKo: "세종특별자치시",
    center: { lat: 36.4802, lon: 127.49 },
    zoom: 11,
  },
  {
    id: 10,
    value: 10,
    titleKo: "경기도",
    center: { lat: 37.2, lon: 127.399 },
    zoom: 10,
  },
  {
    id: 11,
    value: 11,
    titleKo: "강원도",
    center: { lat: 37.7, lon: 128.9 },
    zoom: 10,
  },
  {
    id: 12,
    value: 12,
    titleKo: "충청북도",
    center: { lat: 36.7, lon: 127.7 },
    zoom: 10,
  },
  {
    id: 13,
    value: 13,
    titleKo: "충청남도",
    center: { lat: 36.5184, lon: 126.8 },
    zoom: 10,
  },
  {
    id: 14,
    value: 14,
    titleKo: "전라북도",
    center: { lat: 35.7175, lon: 127.153 },
    zoom: 10,
  },
  {
    id: 15,
    value: 15,
    titleKo: "전라남도",
    center: { lat: 34.9, lon: 126.991 },
    zoom: 10,
  },
  {
    id: 16,
    value: 16,
    titleKo: "경상북도",
    center: { lat: 36.3, lon: 129.1 },
    zoom: 10,
  },
  {
    id: 17,
    value: 17,
    titleKo: "경상남도",
    center: { lat: 35.1, lon: 128.6 },
    zoom: 10,
  },
  {
    id: 18,
    value: 18,
    titleKo: "제주특별자치도",
    center: { lat: 33.35, lon: 126.7 },
    zoom: 11,
  },
];
