export const soilTextureList = [
  { id: 1, value: 1, labelKo: "사토" },
  { id: 2, value: 2, labelKo: "세사양토" },
  { id: 3, value: 3, labelKo: "사양토" },
  { id: 4, value: 4, labelKo: "양토" },
  { id: 5, value: 5, labelKo: "미사질양토" },
  { id: 6, value: 6, labelKo: "양질사토" },
  { id: 7, value: 7, labelKo: "사질식양토" },
  { id: 8, value: 8, labelKo: "미사질식양토" },
  { id: 9, value: 9, labelKo: "식양토" },
  { id: 10, value: 10, labelKo: "미사질식토" },
  { id: 11, value: 11, labelKo: "식토" },
  { id: 12, value: 12, labelKo: "양질세사토" },
  { id: 13, value: 13, labelKo: "양질조사토" },
];
