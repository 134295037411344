import React, { useEffect } from "react";
import styled from "styled-components";

import SoilDataComponent from "./SoilDataComponent";
import { soilTextureList } from "../data/soilData";

const getYearOptions = (minDate, todayDate) => {
  if (minDate && todayDate) {
    let yearOptions = [];
    const minDateYear = new Date(minDate).getFullYear();
    let currentYear = new Date(todayDate).getFullYear();
    while (minDateYear <= currentYear) {
      yearOptions.push(currentYear);
      currentYear -= 1;
    }
    return yearOptions;
  }
};

const FromBoxWrapper = styled.div`
  font-size: 1em;
  line-height: 1em;
  background-color: #999999;
  padding: 10px;
  box-sizing: border-box;
`;

const FromWrapper = styled.div`
  font-size: 1em;
  box-sizing: border-box;
  padding: 10px;
  height: 100%;
  width: 100%;
  max-width: 400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled.label`
  font-weight: bold;
  box-sizing: border-box;
`;

const Select = styled.select`
  font-size: 1em;
  padding-left: 10px;
  margin-top: 5px;
  width: 150px;
  height: 30px;
  box-sizing: border-box;
`;

const FormBox = (props) => {
  const { setTargetYear, targetSoilTexture, setTargetSoilTexture } = props;

  const yearOptions = getYearOptions("2015-01-01", new Date());
  const soilTextureOptions = soilTextureList;

  const onChangeYearOption = (e) => {
    setTargetYear(e.target.value);
  };

  const onChangeSoilTexture = (e) => {
    const soilTextureItem = soilTextureList.filter(
      (item) => item.id === Number(e.target.value)
    )[0];
    if (soilTextureItem) {
      setTargetSoilTexture(soilTextureItem);
    }
  };

  return (
    <FromBoxWrapper>
      <FromWrapper>
        <Label>
          {`연도 선택`}
          <Select onChange={onChangeYearOption}>
            {yearOptions.map((year) => (
              <option key={`yearOption-${year}`} value={year}>
                {year}
              </option>
            ))}
          </Select>
        </Label>
        <Label>
          {`토성 선택`}
          <Select onChange={onChangeSoilTexture} value={targetSoilTexture.id}>
            {soilTextureOptions.map((soilTextrue) => (
              <option
                key={`soilTextureOption-${soilTextrue.id}`}
                value={soilTextrue.id}
              >
                {`${soilTextrue.labelKo}(${soilTextrue.value})`}
              </option>
            ))}
          </Select>
        </Label>
      </FromWrapper>
    </FromBoxWrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
`;

const ChartWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
`;

const EmptyDataWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  background-color: #eeeeee;
  display: flex;
  justify-content: center;
`;

const SubTitle = styled.h2`
  font-size: 1.25em;
  font-weight: bold;
  line-height: 1.2em;
`;

const StationSoilMonitorComponent = (props) => {
  // const { stations } = props;
  const {
    setLoading,
    setError,
    targetYear,
    setTargetYear,
    targetSoilTexture,
    setTargetSoilTexture,
    targetStation,
  } = props;

  useEffect(() => {}, [targetStation]);

  return (
    <Wrapper>
      <FormBox
        setTargetYear={setTargetYear}
        targetSoilTexture={targetSoilTexture}
        setTargetSoilTexture={setTargetSoilTexture}
      />
      {targetStation ? (
        <>
          <SubTitle>
            {`${targetStation.stationName ? targetStation.stationName : "-"}(${
              targetStation.stationCode ? targetStation.stationCode : "-"
            })`}
            <small>/</small>
            {targetYear}
            <small>/</small>
            {`${targetSoilTexture.labelKo}(${targetSoilTexture.value})`}
          </SubTitle>
          <ChartWrapper>
            <SoilDataComponent
              key={targetStation.stationCode}
              spotInfo={targetStation}
              targetYear={targetYear}
              targetSoilTexture={targetSoilTexture}
              setLoading={setLoading}
              setError={setError}
            />
          </ChartWrapper>
        </>
      ) : (
        <>
          <EmptyDataWrapper>
            <SubTitle>
              선택된 관측지점이 없습니다. 왼쪽의 지도에서 관측지점을 클릭하여
              선택해 주세요.
            </SubTitle>
          </EmptyDataWrapper>
        </>
      )}
    </Wrapper>
  );
};

export default StationSoilMonitorComponent;
