import React, { useState, useEffect } from "react";
import styled from "styled-components";

import LoadingComponent from "./components/LoadingComponent";
import HeaderIitleComponent from "./components/HeaderTitleComponent";
import MapContainer from "./components/MapContainer";
import StationSoilMonitorComponent from "./components/StationSoilMonitorComponent";
import FooterComponent from "./components/FooterComponent";

import "./css/common.css";
import "./css/layout.css";
import { provinces } from "./data/provinces";
import { soilTextureList } from "./data/soilData";
import { getRdaStations } from "./context/api";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  min-width: 400px;
`;

const ContentsWrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
`;

const LeftContentsWrapper = styled.div`
  box-sizing: border-box;
  min-width: 400px;
  width: 100%;
  height: 100%;
`;

const RightContentsWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  min-width: 400px;
`;

function App() {
  const today = new Date();
  // const mapComponentRef = React.createRef();

  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [totalSpots, setTotalSpots] = useState([]);
  const [targetYear, setTargetYear] = useState(today.getFullYear());
  const [targetSoilIndex, setTargetSoilIndex] = useState(5);
  const [targetSoilTexture, setTargetSoilTexture] = useState(
    soilTextureList.filter((item) => item.value === 5)[0]
  );
  const [targetProvince, setTargetProvince] = useState(provinces[0]);
  const [targetStation, setTargetStation] = useState();

  useEffect(() => {
    if (targetYear) {
      getRdaStations(
        (data) => {
          if (data.length > 0) {
            // 디폴트로 한개의 지점이 선택되도록 설정
            const defaulStation = data.filter((item) => item.stationName)[0];
            setTargetStation({ ...defaulStation });
            setTotalSpots([
              ...data.map((item) => {
                if (Number(item.id) === Number(defaulStation.id)) {
                  return { ...item, isSelected: true };
                }
                return { ...item };
              }),
            ]);
          } else {
            setTotalSpots(data);
          }
        },
        (data) => {
          setLoading(data);
        },
        (data) => {
          setError(data);
        },
        targetYear
      );
    }
  }, []);

  return (
    <>
      {loading ? <LoadingComponent /> : null}
      <Wrapper className="AppWrapper">
        <HeaderIitleComponent className="service-title" />
        <ContentsWrapper className="contents-wrapper">
          <LeftContentsWrapper className="left-contents">
            <MapContainer
              targetProvince={targetProvince}
              totalSpots={totalSpots}
              selectSpot={(targetSpot) => {
                setTotalSpots(
                  totalSpots.map((item) => {
                    if (Number(item.id) === Number(targetSpot.id)) {
                      return {
                        ...item,
                        isSelected: true,
                      };
                    }
                    return {
                      ...item,
                      isSelected: false,
                    };
                  })
                );
                setTargetStation({ ...targetSpot });
              }}
            />
          </LeftContentsWrapper>
          <RightContentsWrapper className="right-contents">
            <StationSoilMonitorComponent
              setError={setError}
              setLoading={setLoading}
              targetYear={targetYear}
              setTargetYear={setTargetYear}
              targetSoilIndex={targetSoilIndex}
              setTargetSoilIndex={setTargetSoilIndex}
              targetSoilTexture={targetSoilTexture}
              setTargetSoilTexture={setTargetSoilTexture}
              targetStation={targetStation}
              setTargetStation={setTargetStation}
            />
          </RightContentsWrapper>
        </ContentsWrapper>
        <FooterComponent />
      </Wrapper>
    </>
  );
}

export default App;
