import { getAverageOfArray, roundFloat } from "../utils/common";

const getInBetweenValue = (value, minValue = 0, maxValue = 40) => {
  if (value <= minValue) {
    return minValue;
  }
  if (value >= maxValue) {
    return maxValue;
  }
  return roundFloat(value);
};

const getMoistureEstimationValue = (AverageTempInTheLastNDays, rain) => {
  const baseValue = 10.5033009;
  const tempMultiplicand1 = 0.722217;
  const tempMultiplicand2 = -0.0289881;
  const tempMultiplicand3 = -0.0009671;
  const rainMultiplicand = 0.3950746;

  const result =
    baseValue +
    tempMultiplicand1 * AverageTempInTheLastNDays +
    tempMultiplicand2 * AverageTempInTheLastNDays ** 2 +
    tempMultiplicand3 * AverageTempInTheLastNDays ** 3 +
    rainMultiplicand * rain;

  return result;
};

export const getDailyMoistureEstimationValues = (dailyWeathers) => {
  const N_DAYS = 6;
  const MIN_MOISTURE = 0;
  const MAX_MOISTURE = 40;

  const DailyAverageObsTempsInTheLastNDays = [];
  let prevAverageTemp = 0;

  const updateDailyAverageObsTempsInTheLastNDays = (todayRain) => {
    if (DailyAverageObsTempsInTheLastNDays.length >= N_DAYS) {
      // 첫번째 원소 제거
      DailyAverageObsTempsInTheLastNDays.shift();
    }
    DailyAverageObsTempsInTheLastNDays.push(todayRain);
  };

  return dailyWeathers.map((weather) => {
    // ta(평균온도)가 결측이면 전날의 평균온도를 사용
    const todayAverageTemp =
      weather.ta !== 0 && !!weather.ta
        ? parseFloat(weather.ta)
        : prevAverageTemp;
    // rain이 결측이면 0으로 간주(0: 비가 안왔음)
    const todayRain =
      weather.rn !== 0 && !!weather.rn ? parseFloat(weather.rn) : 0;

    prevAverageTemp = todayAverageTemp;
    updateDailyAverageObsTempsInTheLastNDays(todayAverageTemp);

    // 일별 soil moisture 값의 범위는 0 ~ 40
    return getInBetweenValue(
      getMoistureEstimationValue(
        getAverageOfArray(DailyAverageObsTempsInTheLastNDays),
        todayRain
      ),
      MIN_MOISTURE,
      MAX_MOISTURE
    );
  });
};
