import React from "react";
import styled from "styled-components";

import copyRight from "../img/copyright-logo.png";

const Footer = styled.footer`
  background-color: #999999;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  padding: 5px 0px 5px 10px;
  height: 50px;
  display: flex;
  align-items: center;
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
`;

const Img = styled.img`
  height: 100%;
  padding: 0;
  margin: 0;
  padding-right: 50px;
`;

const P = styled.p`
  padding: 0;
  margin: 0;
  color: #fff;
  font-size: 0.8em;
  // font-weight: bold;
  text-align: center;
`;

const FooterComponent = () => {
  return (
    <Footer className="footer-component">
      <Img src={copyRight} alt="로고" />
      {/* 
      <H1>조사료 토양수분 모니터링 시스템</H1> */}
      <TitleWrapper>
        <P>
          31000 충청남도 천안시 서북구 성홥은 신방1길 114
          국립축산과학원축산자원개발부
        </P>
        <P>© National Institute of Animal Science. All Rights Reserved.</P>
      </TitleWrapper>
    </Footer>
  );
};

export default FooterComponent;
