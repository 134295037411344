export const getAverageOfArray = (arr) => {
  if (arr.length <= 0) {
    return 0;
  }
  return arr.reduce((p, c) => p + c, 0) / arr.length;
};

export const roundFloat = (floatNum, roundNum = 2) => {
  const roundedNumString = parseFloat(floatNum).toFixed(roundNum);
  return parseFloat(roundedNumString);
};
