import React, { useEffect, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

const SoilMoistureChart = (props) => {
  const { data } = props;

  const [chartOption, setChartOption] = useState({
    chart: {
      type: "spline",
      // width: "100%",
      minWidth: "300px",
    },
    title: {
      text: "일별 토양수분 그래프",
    },
    // subtitle: {
    //   text: "Source: WorldClimate.com",
    // },
    xAxis: {
      //   categories: [],
      //   accessibility: {
      //     description: "날짜",
      //   },
    },
    yAxis: {
      title: {
        text: "토양수분함량 비율 (%)",
      },
      labels: {
        formatter: function () {
          return this.value + "%";
        },
      },
    },
    tooltip: {
      crosshairs: true,
      shared: true,
    },
    plotOptions: {
      spline: {
        marker: {
          radius: 4,
          lineColor: "#666666",
          lineWidth: 1,
        },
      },
    },
    series: [
      //   {
      //     name: "관측값",
      //     marker: {
      //       symbol: "square",
      //     },
      //     data: [
      //       7.0, 6.9, 9.5, 14.5, 18.2, 21.5, 25.2, 26.5, 23.3, 18.3, 13.9, 9.6,
      //     ],
      //   },
      //   {
      //     name: "추정값",
      //     marker: {
      //       symbol: "diamond",
      //     },
      //     data: [
      //       3.9, 4.2, 5.7, 8.5, 11.9, 15.2, 17.0, 16.6, 14.2, 10.3, 6.6, 4.8,
      //     ],
      //   },
    ],
  });

  useEffect(() => {
    if (data) {
      setChartOption({
        ...chartOption,
        xAxis: {
          categories: [...data.dates],
          accessibility: {
            description: "날짜",
          },
        },
        series: [
          {
            name: "관측값",
            marker: {
              symbol: "circle",
            },
            data: [...data.observedSoilMoistures],
          },
          {
            name: "PBCast 모델 추정값",
            marker: {
              symbol: "diamond",
            },
            data: [...data.fBCastMoistures],
          },
          {
            name: "Simple 모델 추정값",
            marker: {
              symbol: "square",
            },
            data: [...data.simpleMoistures],
          },
        ],
      });
    }
  }, [data]);

  return <HighchartsReact highcharts={Highcharts} options={chartOption} />;
};

export default SoilMoistureChart;
